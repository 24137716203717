import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Intro from "./Intro";

const Main = () => {
  return (
    <Router basename={"/"}>
      {/* The Switch decides which component to show based on the current URL.*/}
      <Route exact path="/" component={Intro}></Route>
    </Router>
  );
};

export default Main;
