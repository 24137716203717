import { useEffect, useRef } from "react";
import * as Matter from "matter-js";
import cookie from "../assets/cookie.svg";
import cookie2 from "../assets/cookie2.svg";
import cookie3 from "../assets/cookie3.svg";
import cookie4 from "../assets/cookie4.svg";
import cookie5 from "../assets/cookie5.svg";
import cookie6 from "../assets/cookie6.svg";
import cookie7 from "../assets/cookie7.svg";
import { Engine, Render, Bodies, World, Runner } from "matter-js";

function Comp(props) {
  useEffect(() => {
    if (props.itemCounter < 1) {
      const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty("--app-height", `${window.innerHeight}px`);
      };
      window.addEventListener("resize", appHeight);
      appHeight();
    }
  }, [props.itemCounter]);

  const scene = useRef();
  const isPressed = useRef(false);
  const engine = useRef(Engine.create());

  useEffect(() => {
    const cw = document.body.clientWidth;
    const rootDocument = document.getElementById("body");
    const ch = rootDocument.clientHeight;
    console.log(ch);

    const render = Render.create({
      element: scene.current,
      engine: engine.current,
      options: {
        width: cw,
        height: ch,
        wireframes: false,
        background: "transparent",
      },
    });

    // add walls
    var wallopts = {
      isStatic: true,
      restitution: 0.2,
      friction: 1,
      render: {
        visible: false,
      },
    };

    World.add(engine.current.world, [
      Bodies.rectangle(cw / 2, ch + 23, cw, 50, wallopts),
      Bodies.rectangle(cw / 2, -50, cw + 200, 100, wallopts),
      Bodies.rectangle(cw + 50, ch / 2, 100, ch, wallopts),
      Bodies.rectangle(-50, ch / 2, 100, ch, wallopts),
    ]);

    /** Set up relative positions and scales **/
    var VIEW = {};
    VIEW.width = window.innerWidth;
    VIEW.height = window.innerHeight;
    VIEW.centerX = VIEW.width / 2;
    VIEW.centerY = VIEW.height / 2;
    VIEW.offsetX = VIEW.width / 2;
    VIEW.offsetY = VIEW.height / 2;

    var bodiesDom = document.getElementsByClassName("matter-body");
    for (var i = 0; i < bodiesDom.length; i++) {
      var body = Bodies.rectangle(
        bodiesDom[i].offsetLeft + bodiesDom[i].offsetWidth / 2,
        bodiesDom[i].offsetTop + bodiesDom[i].offsetHeight / 2,
        //(VIEW.width * bodiesDom[i].offsetWidth) / window.innerWidth,
        bodiesDom[i].offsetWidth - 25,
        //(VIEW.height * bodiesDom[i].offsetHeight) / window.innerHeight,
        bodiesDom[i].offsetHeight - 25,
        {
          restitution: 0.05,
          friction: 2,
          frictionAir: 0,
          frictionStatic: 20,
          density: 100,
          //chamfer: { radius: 4 },
          isStatic: true,
          render: {
            fillStyle: "transparent",
          },
        }
      );
      bodiesDom[i].id = body.id;
      World.add(engine.current.world, body);
    }

    // REFRESH PAGE IF WINDOW RESIZED
    window.addEventListener("resize", () => {
      window.location.reload();
    });

    Runner.run(engine.current);
    Render.run(render);

    return () => {
      Render.stop(render);
      World.clear(engine.current.world);
      Engine.clear(engine.current);
      render.canvas.remove();
      render.canvas = null;
      render.context = null;
      render.textures = {};
    };
  }, []);

  var cookies = [cookie, cookie2, cookie3, cookie4, cookie5, cookie6, cookie7];

  useEffect(() => {
    if (props.itemCounter > 0) {
      var mouseEvent = props.mouseEvent;
      var centerX = mouseEvent.clientX;
      var centerY = mouseEvent.clientY;
      var random = Math.round(Math.random() * (6 - 0) + 0);

      // variation for extra small devices
      var cookieBaseSize = 30;
      var cookieSizeVariation = 30;
      if (window.innerWidth <= 575) {
        cookieBaseSize = 15;
        cookieSizeVariation = 20;
      }

      // cookie size + variation calculation
      var randSize = Math.random() * cookieSizeVariation;
      var size = cookieBaseSize + randSize;
      var customMass =
        0.1 +
        ((1 - 0.2) * (size - cookieBaseSize)) /
          (cookieBaseSize * 2 - cookieBaseSize); //low2 + (high2 - low2) * (value - low1) / (high1 - low1)

      const ball = Bodies.circle(centerX, centerY, size, {
        mass: customMass,
        restitution: 0.5,
        friction: 0.2,
        render: {
          fillStyle: "transparent",
          sprite: {
            texture: cookies[random],
            xScale: (size / 515) * 2,
            yScale: (size / 515) * 2,
          },
        },
      });
      World.add(engine.current.world, [ball]);
      const plusOrMinus = Math.random() < 0.5 ? -1 : 1;
      const velocityX = (Math.random() * (10 - 0) + 0) * plusOrMinus;
      const velocityY = (Math.random() * (10 - 5) + 5) * -1;

      Matter.Body.setVelocity(ball, { x: velocityX, y: velocityY });
      Matter.Body.setAngularVelocity(ball, 0.1);
    }
  }, [props.itemCounter]);

  return (
    <div
      id="canvas-size"
      ref={scene}
      style={{
        width: "calc(100vw - (100vw - 100%))",
        height: "100vh",
        position: "absolute",
        zindex: "1000",
      }}
    />
  );
}

export default Comp;
