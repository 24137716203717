import React, { useState, useEffect } from "react";
import Comp from "./Comp";
import { ReactComponent as TikTokIcon } from "../assets/icons/tiktok-icon.svg";
import { ReactComponent as EmailIcon } from "../assets/icons/email-icon.svg";
import { ReactComponent as InstaIcon } from "../assets/icons/instagram-icon.svg";
import { ReactComponent as TwitterIcon } from "../assets/icons/twitter-icon.svg";
import { ReactComponent as CoffeeIcon } from "../assets/icons/coffee-icon.svg";
import cookie from "../assets/cookie4.svg";
import cookiePressed from "../assets/cookie4_pressed.svg";
import Div100vh from "react-div-100vh";

function Intro() {
  let [itemCounter, setItemCounter] = useState(0);
  let [mouseEvent, setMouseEvent] = useState(null);
  let [activeCookie, setActiveCookie] = useState(0);

  const cookieButton = [cookie, cookiePressed];

  function increaseCounter(e) {
    setItemCounter(itemCounter + 1);
    setMouseEvent(e);
  }

  useEffect(() => {
    console.log("CLICKED  " + itemCounter);
  }, [itemCounter]);

  return (
    <div>
      <Comp itemCounter={itemCounter} mouseEvent={mouseEvent}></Comp>
      <header></header>
      <div className="full-screen">
        <div className="content-centered">
          <main>
            <section>
              <div className="text-left container">
                <div className="row py-lg-4 row-intro">
                  <div className="col-lg-12 col-md-auto column" id="intro-div">
                    <h1 className="fw-light" style={{ display: "inline" }}>
                      Hi! I'm <strong>Poruba</strong>
                    </h1>
                    <p className="lead text-muted intro-text">
                      Creative coder who enjoys building fun and silly projects.
                    </p>
                    {/* <button
                  className="color-button"
                  onClick={(e) => {
                    increaseCounter(e);
                  }}
                  id="item-button">
                  Cookie?
                </button> */}
                  </div>
                </div>
              </div>
            </section>
            <section id="posters-section">
              <div className="text-left container portfolio-section">
                <div className="row justify-content-end">
                  <div className="div-card col-md-4 col-sm-12 column matter-body">
                    <a href="https://poruba.fun/poster">
                      <div className="div-poster "></div>

                      {/* <img
                    src={guessThePosterThumbnail}
                    alt="guessImageThumbnail"
                    className="imgPoster img-fluid"></img> */}
                      <span className="project-details">
                        <h5 className="project-name">Guess the movie</h5>
                        <p className="project-description">
                          Without seeing key poster elements
                        </p>
                      </span>
                    </a>
                  </div>

                  <div className="div-card col-md-4 col-sm-12 column overlay matter-body hidden">
                    {/* <img
                  src={comingSoon}
                  alt="guessImageThumbnail"
                  className="imgPoster img-fluid"></img> */}
                    <div className="tmp-poster">
                      <p className="coming-soon">Mini project coming soon</p>
                    </div>{" "}
                  </div>
                  <div className="div-card col-md-4 col-sm-12 column overlay matter-body hidden">
                    <div className="tmp-poster">
                      {/* <img
                    src={comingSoon}
                    alt="guessImageThumbnail"
                    className="imgPoster img-fluid"></img> */}
                      <p className="coming-soon">Mini project coming soon</p>
                    </div>
                  </div>
                  <div className="portfolio-inside">
                    <button
                      className="cookie-container-button front"
                      onClick={(e) => {
                        increaseCounter(e);
                      }}
                      onPointerDown={(e) => {
                        setActiveCookie(1);
                      }}
                      onPointerUp={(e) => {
                        setActiveCookie(0);
                      }}
                      id="item-button"></button>
                    <div className="cookie-container-button">
                      <img
                        src={cookieButton[activeCookie]}
                        alt="cookie"
                        className="cookie-button"></img>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <footer className="text-muted py-5 footer">
            <div className="container">
              <div className="column matter-body">
                <p className="mb-1 inline-p footer-text">
                  Made with ❤ by Poruba
                </p>
                <br></br>
                <p className="mb-0 footer-text">
                  Do you like my work?{" "}
                  <a
                    href="https://www.buymeacoffee.com/poruba"
                    className="coffee-link">
                    Buy me a coffee :)
                  </a>
                </p>
                <div className="icons-div">
                  <a
                    href="https://www.tiktok.com/@poruba.fun"
                    target="_blank"
                    rel="noreferrer">
                    <TikTokIcon className="footer-icon" />
                  </a>
                  <a
                    href="https://twitter.com/poruba_dot_fun"
                    target="_blank"
                    rel="noreferrer">
                    <TwitterIcon className="footer-icon" />
                  </a>
                  <a
                    href="https://www.instagram.com/poruba.fun/"
                    target="_blank"
                    rel="noreferrer">
                    <InstaIcon className="footer-icon" />
                  </a>
                  <a
                    href="mailto:hi@poruba.fun"
                    target="_blank"
                    rel="noreferrer">
                    <EmailIcon className="footer-icon" />
                  </a>
                  <a
                    href="https://www.buymeacoffee.com/poruba"
                    target="_blank"
                    rel="noreferrer">
                    <CoffeeIcon className="footer-icon" />
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Intro;
